import helpers from '@/helpers/index'

export default {
     handleErrosEmpresa(errorData, errorForm, errorFormAdresses, addressesSize, errorApiEnderecoContato) {
        let error = errorData.errors;
        if(typeof error == 'string') {
            if(error == 'Um dos enderecos informados tem uma cidade que não possui uma unidade vinculada'){
                helpers.modalErroEmpresa("Um dos enderecos informados tem uma cidade que não possui uma unidade vinculada");
            }else{
                errorForm.dadosPrincipais.documento = error;
            }
        }else if(typeof error == 'undefined'){
            helpers.modalErroServidorPadrao();
        }else{

            for (let i = 0; i < addressesSize; i++) {
                if(i === 0){
                    errorForm.endereco.cep = error['endereco.'+i+'.cep'] ? error['endereco.'+i+'.cep'][0] : '';
                    errorForm.endereco.numero = error['endereco.'+i+'.numero'] ? error['endereco.'+i+'.numero'][0] : '';
                    errorForm.endereco.ponto_referencia = error['endereco.'+i+'.ponto_referencia'] ? error['endereco.'+i+'.ponto_referencia'][0] : '';
                    errorForm.endereco.id_cidade = error['endereco.'+i+'.id_cidade'] ? error['endereco.'+i+'.id_cidade'][0] : '';
                    errorForm.endereco.logradouro = error['endereco.'+i+'.logradouro'] ? error['endereco.'+i+'.logradouro'][0] : '';
                    errorForm.endereco.bairro = error['endereco.'+i+'.bairro'] ? error['endereco.'+i+'.bairro'][0] : '';
                    errorForm.endereco.error = error['endereco.'+i] ? error['endereco.'+i][0] : '';
                }else{
                    errorFormAdresses[i-1].cep = error['endereco.'+i+'.cep'] ? error['endereco.'+i+'.cep'][0] : '';
                    errorFormAdresses[i-1].numero = error['endereco.'+i+'.numero'] ? error['endereco.'+i+'.numero'][0] : '';
                    errorFormAdresses[i-1].ponto_referencia = error['endereco.'+i+'.ponto_referencia'] ? error['endereco.'+i+'.ponto_referencia'][0] : '';
                    errorFormAdresses[i-1].id_cidade = error['endereco.'+i+'.id_cidade'] ? error['endereco.'+i+'.id_cidade'][0] : '';
                    errorFormAdresses[i-1].logradouro = error['endereco.'+i+'.logradouro'] ? error['endereco.'+i+'.logradouro'][0] : '';
                    errorFormAdresses[i-1].bairro = error['endereco.'+i+'.bairro'] ? error['endereco.'+i+'.bairro'][0] : '';
                    errorFormAdresses[i-1].error = error['endereco.'+i] ? error['endereco.'+i][0] : '';
                }
            }

            for (let i = 0; i < errorApiEnderecoContato.length; i++) {
                errorApiEnderecoContato[i].cargo = error['endereco.'+i+'.contato.cargo'] ? error['endereco.'+i+'.contato.cargo'][0] : '';
                errorApiEnderecoContato[i].email = error['endereco.'+i+'.contato.email'] ? error['endereco.'+i+'.contato.email'][0] : '';
                errorApiEnderecoContato[i].nome = error['endereco.'+i+'.contato.nome'] ? error['endereco.'+i+'.contato.nome'][0] : '';
                errorApiEnderecoContato[i].telefone = error['endereco.'+i+'.contato.telefone'] ? error['endereco.'+i+'.contato.telefone'][0] : '';
                errorApiEnderecoContato[i].telefone_secundario = error['endereco.'+i+'.contato.telefone_secundario'] ? error['endereco.'+i+'.contato.telefone_secundario'][0] : '';
                errorApiEnderecoContato[i].error = error['endereco.'+i+'.contato'] ? error['endereco.'+i+'.contato'][0] : '';
            }

            errorForm.dadosPrincipais.documento = error['dadosPrincipais.documento'] ? error['dadosPrincipais.documento'][0] : '';
            errorForm.dadosPrincipais.nome_fantasia = error['dadosPrincipais.nome_fantasia'] ? error['dadosPrincipais.nome_fantasia'][0] : '';
            errorForm.dadosPrincipais.razao_social = error['dadosPrincipais.razao_social'] ? error['dadosPrincipais.razao_social'][0] : '';
            errorForm.dadosPrincipais.nome = error['dadosPrincipais.nome'] ? error['dadosPrincipais.nome'][0] : '';
            errorForm.dadosPrincipais.inscricao_estadual = error['dadosPrincipais.inscricao_estadual'] ? error['dadosPrincipais.inscricao_estadual'][0] : '';
            errorForm.dadosPrincipais.data_nascimento = error['dadosPrincipais.data_nascimento'] ? error['dadosPrincipais.data_nascimento'][0] : '';
            errorForm.dadosPrincipais.nome_mae = error['dadosPrincipais.nome_mae'] ? error['dadosPrincipais.nome_mae'][0] : '';
            errorForm.dadosPrincipais.id_nacionalidade = error['dadosPrincipais.id_nacionalidade'] ? error['dadosPrincipais.id_nacionalidade'][0] : '';
            errorForm.dadosPrincipais.error = error['dadosPrincipais'] ? error['dadosPrincipais'][0] : '';

            errorForm.dadosComplementares.cnae = error['dadosComplementares.cnae'] ? error['dadosComplementares.cnae'][0] : '';
            errorForm.dadosComplementares.id_grupo = error['dadosComplementares.id_grupo'] ? error['dadosComplementares.id_grupo'][0] : '';
            errorForm.dadosComplementares.id_operador = error['dadosComplementares.id_operador'] ? error['dadosComplementares.id_operador'][0] : '';
            errorForm.dadosComplementares.base_nacional = error['grupoOperador'] ? error['grupoOperador'][0] : '';
            
            errorForm.dadosComplementares.fpas = error['dadosComplementares.fpas'] ? error['dadosComplementares.fpas'][0] : '';
            errorForm.dadosComplementares.numero_de_trabalhadores = error['dadosComplementares.numero_de_trabalhadores'] ? error['dadosComplementares.numero_de_trabalhadores'][0] : '';
            errorForm.dadosComplementares.id_tipo_empresa = error['dadosComplementares.id_tipo_empresa'] ? error['dadosComplementares.id_tipo_empresa'][0] : '';
            errorForm.dadosComplementares.error = error['dadosComplementares'] ? error['dadosComplementares'][0] : '';

            errorForm.faturamento.cnpj_faturamento = error['cnpj_faturamento'] ? error['cnpj_faturamento'][0] : '';
            errorForm.faturamento.error = error['faturamento'] ? error['faturamento'][0] : '';

            errorForm.contatoPrincipal.nome = error['contatoPrincipal.nome'] ? error['contatoPrincipal.nome'][0] : '';
            errorForm.contatoPrincipal.cargo = error['contatoPrincipal.cargo'] ? error['contatoPrincipal.cargo'][0] : '';
            errorForm.contatoPrincipal.email = error['contatoPrincipal.email'] ? error['contatoPrincipal.email'][0] : '';
            errorForm.contatoPrincipal.telefone = error['contatoPrincipal.telefone'] ? error['contatoPrincipal.telefone'][0] : '';
            errorForm.contatoPrincipal.telefone_secundario = error['contatoPrincipal.telefone_secundario'] ? error['contatoPrincipal.telefone_secundario'][0] : '';
            errorForm.contatoPrincipal.error = error['contatoPrincipal'] ? error['contatoPrincipal'][0] : '';

            if (errorForm.atendimento) {
                errorForm.atendimento.valor_diferenciado = error['atendimento.valor_diferenciado'] ? error['atendimento.valor_diferenciado'][0] : '';
                errorForm.atendimento.id_tipo_preco = error['atendimento.id_tipo_preco'] ? error['atendimento.id_tipo_preco'][0] : '';
                errorForm.atendimento.possui_convenio = error['atendimento.possui_convenio'] ? error['atendimento.possui_convenio'][0] : '';
                errorForm.atendimento.id_unidade = error['atendimento.id_unidade'] ? error['atendimento.id_unidade'][0] : '';
                errorForm.atendimento.error = error['atendimento'] ? error['atendimento'][0] : '';
            }
        }
    }
}
<template>
  <section>
    <CadastroContatoModal
      :showModal="showModalEndereco"
      @cancel="closeModal"
      @closeModal="closeModal"
      v-model="contatoEnderecoSelecionado"
    />
    <div>
      <b-overlay :show="loadingShowCep" rounded="lg" opacity="0.6" class="w-100 mb-1">
        <b-row>
          <b-col class="mb-1 mt-n1" cols="12">
            <div class="d-flex justify-content-start align-items-center">
              <feather-icon 
                size="16"
                icon="HelpCircleIcon" 
                class="mr-50 text-custom-blue"
              />
              <b-link @click="redirectToFaqSection">
                <strong class="text-custom-blue label_ajuda">
                  Cadastre abaixo o endereço de sua empresa e, caso deseje solicitar doses e realizar a vacinação
                  também em outros endereços, clique em <i>Adicionar endereço</i> e preencha com as informações solicitadas.
                </strong>
              </b-link>
            </div>
          </b-col>
          <b-col class="mb-2 label_endereco" cols="12">Endereço 1</b-col>
          <b-col xl="4" cols="12">
              <b-form-group 
                label="CEP"
                label-for="cep-input"
                label-class="font_size_label_empresa"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CEP"
                  :rules="'length:8'"
                >
                  <vue-cleave
                    id="cep-input"
                    v-model="value.cep"
                    :raw="true"
                    :options="mascaraCep"
                    class="form-control"
                    placeholder="00000-000"
                    name="Cep"
                    @input="() => { validaCep(); emitUpdateEnderecoTabContato() }"
                    :disabled="loadingShowCep"
                  />
                  <small v-if="errors[0]" class="text-danger">
                    Este campo deve ter 8 números
                  </small>
                  <small v-if="cepError.length > 0" class="text-danger">
                    {{ cepError }}
                  </small>
                  <small v-if="errorApi.cep" class="text-danger">
                    {{ errorApi.cep }}
                  </small>
                  <small v-if="errorApi.error" class="text-danger">
                    {{ errorApi.error }}
                  </small>
                </validation-provider>
              </b-form-group>
          </b-col>
          <b-col xl="6" cols="12">
            <b-form-group 
              label="Rua/Avenida"
              label-for="rua-avenida-input"
              label-class="font_size_label_empresa"
            >
              <b-form-input 
                  :state="errorApi.logradouro || errorApi.error ? false:null"
                  id="rua-avenida-input" 
                  v-model="value.logradouro"
                  placeholder="Rua ou avenida"
                  v-mask="('X'.repeat(200))"
                  @input="emitUpdateEnderecoTabContato"
              />
              <small v-if="errorApi.logradouro" class="text-danger">
                  {{ errorApi.logradouro }}
              </small>
              <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
              </small>
            </b-form-group>
          </b-col>
          <b-col xl="4" cols="12">
            <b-form-group 
              label="Número"
              label-for="numero-input"
              label-class="font_size_label_empresa"
            >
              <b-form-input 
                  :state="errorApi.numero || errorApi.error ? false:null"
                  id="numero-input" 
                  v-model="value.numero"
                  placeholder="Número"
                  v-mask="('#'.repeat(6))"
                  @input="emitUpdateEnderecoTabContato"
              />
              <small v-if="errorApi.numero" class="text-danger">
                  {{ errorApi.numero }}
              </small>
              <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
              </small>
            </b-form-group>
          </b-col>
          <b-col xl="4" cols="12">
            <b-form-group 
              label="Ponto de Referência"
              label-for="ponto-de-referencia-input"
              label-class="font_size_label_empresa"
            >
              <b-form-input 
                  :state="errorApi.ponto_referencia || errorApi.error ? false:null"
                  id="ponto-de-referencia-input" 
                  v-model="value.ponto_referencia"
                  placeholder="Ponto de Referência"
                  v-mask="('X'.repeat(200))"
                  @input="emitUpdateEnderecoTabContato"
              />
              <small v-if="errorApi.ponto_referencia" class="text-danger">
                  {{ errorApi.ponto_referencia }}
              </small>
              <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
              </small>
            </b-form-group>
          </b-col>
          <b-col xl="4" cols="12">
            <b-form-group 
              label="Bairro"
              label-for="bairro-input"
              label-class="font_size_label_empresa"
            >
              <b-form-input 
                  :state="errorApi.bairro || errorApi.error ? false:null"
                  id="bairro-input" 
                  v-model="value.bairro"
                  placeholder="Bairro"
                  v-mask="('X'.repeat(200))"
                  @input="emitUpdateEnderecoTabContato"
              />
              <small v-if="errorApi.bairro" class="text-danger">
                  {{ errorApi.bairro }}
              </small>
              <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
              </small>
            </b-form-group>
          </b-col>
          <b-col xl="4" cols="12">
            <b-form-group 
              :state="errorApi.error ? false:null"
              label="Estado"
              label-for="estado-input"
              label-class="font_size_label_empresa"
            >
              <v-select
                id="estados-input"
                @input="preencheCidades"
                :options="estados"
                v-model="estadoSelecionado"
                :disabled="estadoDisabled"
                placeholder="Selecione o Estado"
                label="nome"
                item-text="nome"
                item-value="uf"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
              <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
              </small>
            </b-form-group>
          </b-col>
          <b-col xl="4" cols="12">
            <b-overlay :show="loadingShowCidade" rounded="lg" opacity="0.6" class="w-100">
              <b-form-group 
                :state="errorApi.id_cidade || errorApi.error ? false:null"
                label="Cidade"
                label-for="cidade-input"
                label-class="font_size_label_empresa"
              >
                <v-select
                  id="cidades-input"
                  :disabled="cidadeDisabled"
                  :options="cidades"
                  :value="cidade"
                  @input="selecionaCidade"
                  placeholder="Selecione a cidade"
                  label="nome"
                  item-text="nome"
                  item-value="id_cidade"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small v-if="errorApi.id_cidade" class="text-danger">
                    {{ errorApi.id_cidade }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                    {{ errorApi.error }}
                </small>
              </b-form-group>
            </b-overlay>
          </b-col>
          <b-col xl="4" cols="12" class="d-flex justify-content-start align-items-start">
            <b-button 
              v-if="!temContatoCompleto(value.contato)"
              id="modalCadastroContatoEndereco" 
              variant="link" 
              class="colorBlue mt-bt-remove" 
              @click.prevent="openModalCadastroContatoEndereco(value.contato)">
              <feather-icon icon="PlusIcon"/>
              Cadastrar contato</b-button>
          </b-col>
        </b-row>
        <div class="containerEnderecoContato d-flex justify-content-between">
          <div class="containerContato">
            <p class="titleEnderecoContato">Contato adicionado a esse endereço:</p>
            <p class="textEnderecoContato" v-if="temContatoCompleto(value.contato)">
              {{ 
                  (value.contato.nome ? value.contato.nome : '--') + ', ' + 
                  (value.contato.cargo ? value.contato.cargo : '--') + ', E-mail: ' + 
                  (value.contato.email ? value.contato.email : '--') + ' - '
              }} 

              {{ (value.contato.telefone ? value.contato.telefone : '--') | VMask('(##)#####-####') }}.
            </p>
            <p class="textEnderecoContato" v-else >-</p>
          </div>
          <div class="containerEditButton d-flex justify-content-center align-items-center">
            <feather-icon 
              v-if="temContatoCompleto(value.contato)"
              id="editContatoPrincipal" 
              class="text-custom-blue " 
              role="button"
              icon="EditIcon" 
              size="18"
              @click.prevent="openModalCadastroContatoEndereco(value.contato)"
            />
          </div>
        </div>
      </b-overlay>
      <div 
        v-for="(address, key) in addresses"
        :key="key"
      >
        <b-overlay :show="addressesInternalUse[key].loadingShowCep" rounded="lg" opacity="0.6" class="w-100 mb-2">
          <b-row>
            <b-col class="mb-2 label_endereco" cols="12">Endereço {{ key + 2 }}</b-col>
            <b-col xl="4" cols="12">
                <b-form-group 
                  label="CEP"
                  :label-for="'cep-input'+key"
                  label-class="font_size_label_empresa"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="'CEP'+key"
                    :rules="'length:8'"
                  >
                    <vue-cleave
                      :id="'cep-input'+key"
                      v-model="address.cep"
                      :raw="true"
                      :options="mascaraCep"
                      class="form-control"
                      placeholder="00000-000"
                      :name="'Cep-'+key"
                      @input="validaCep(key)"
                      :disabled="addressesInternalUse[key].loadingShowCep"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo deve ter 8 números
                    </small>
                    <small v-if="addressesInternalUse[key].cepError.length > 0" class="text-danger">
                      {{ addressesInternalUse[key].cepError }}
                    </small>
                    <small v-if="addressesErrorApi[key].cep" class="text-danger">
                      {{ addressesErrorApi[key].cep }}
                    </small>
                    <small v-if="addressesErrorApi[key].error" class="text-danger">
                      {{ addressesErrorApi[key].error }}
                    </small>
                  </validation-provider>
                </b-form-group>
            </b-col>
            <b-col xl="6" cols="12">
              <b-form-group 
                label="Rua/Avenida"
                :label-for="'rua-avenida-input'+key"
                label-class="font_size_label_empresa"
              >
                <b-form-input 
                    :state="addressesErrorApi[key].logradouro || addressesErrorApi[key].error ? false:null"
                    :id="'rua-avenida-input'+key" 
                    v-model="address.logradouro"
                    placeholder="Rua ou avenida"
                    v-mask="('X'.repeat(200))"
                />
                <small v-if="addressesErrorApi[key].logradouro" class="text-danger">
                  {{ addressesErrorApi[key].logradouro }}
                </small>
                <small v-if="addressesErrorApi[key].error" class="text-danger">
                    {{ addressesErrorApi[key].error }}
                </small>
              </b-form-group>
            </b-col>
            <b-col xl="4" cols="12">
              <b-form-group 
                label="Número"
                :label-for="'numero-input'+key"
                label-class="font_size_label_empresa"
              >
                <b-form-input 
                    :state="addressesErrorApi[key].numero || addressesErrorApi[key].error ? false:null"
                    :id="'numero-input'+key" 
                    v-model="address.numero"
                    placeholder="Número"
                    v-mask="('#'.repeat(6))"
                />
                <small v-if="addressesErrorApi[key].numero" class="text-danger">
                  {{ addressesErrorApi[key].numero }}
                </small>
                <small v-if="addressesErrorApi[key].error" class="text-danger">
                    {{ addressesErrorApi[key].error }}
                </small>
              </b-form-group>
            </b-col>
            <b-col xl="4" cols="12">
              <b-form-group 
                label="Ponto de Referência"
                :label-for="'ponto-de-referencia-input'+key"
                label-class="font_size_label_empresa"
              >
                <b-form-input 
                    :state="addressesErrorApi[key].ponto_referencia || addressesErrorApi[key].error ? false:null"
                    :id="'ponto-de-referencia-input'+key" 
                    v-model="address.ponto_referencia"
                    placeholder="Ponto de Referência"
                    v-mask="('X'.repeat(200))"
                />
                <small v-if="addressesErrorApi[key].ponto_referencia" class="text-danger">
                  {{ addressesErrorApi[key].ponto_referencia }}
                </small>
                <small v-if="addressesErrorApi[key].error" class="text-danger">
                    {{ addressesErrorApi[key].error }}
                </small>
              </b-form-group>
            </b-col>
            <b-col xl="4" cols="12">
              <b-form-group 
                label="Bairro"
                :label-for="'bairro-input'+key"
                label-class="font_size_label_empresa"
              >
                <b-form-input 
                    :state="addressesErrorApi[key].bairro || addressesErrorApi[key].error ? false:null"
                    :id="'bairro-input'+key" 
                    v-model="address.bairro"
                    placeholder="Bairro"
                    v-mask="('X'.repeat(200))"
                />
                <small v-if="addressesErrorApi[key].bairro" class="text-danger">
                  {{ addressesErrorApi[key].bairro }}
                </small>
                <small v-if="addressesErrorApi[key].error" class="text-danger">
                    {{ addressesErrorApi[key].error }}
                </small>
              </b-form-group>
            </b-col>
            <b-col xl="4" cols="12">
              <b-form-group 
                :state="addressesErrorApi[key].error ? false:null"
                label="Estado"
                :label-for="'estado-input'+key"
                label-class="font_size_label_empresa"
              >
                <v-select
                  :id="'estados-input'+key"
                  @input="(event) => {
                    preencheCidadesAdicional(event, key);
                    emitUpdateEnderecoTabContato();
                    preecnheEstadoEndereco(event, key);
                  }"
                  :options="addressesInternalUse[key].estados"
                  :disabled="addressesInternalUse[key].estadoDisabled"
                  v-model="addressesInternalUse[key].estadoSelecionado"
                  placeholder="Selecione o Estado"
                  label="nome"
                  item-text="nome"
                  item-value="uf"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small v-if="addressesErrorApi[key].error" class="text-danger">
                  {{ addressesErrorApi[key].error }}
                </small>
              </b-form-group>
            </b-col>
            <b-col xl="4" cols="12">
              <b-overlay :show="addressesInternalUse[key].loadingShowCidade" rounded="lg" opacity="0.6" class="w-100">
                <b-form-group 
                  :state="addressesErrorApi[key].id_cidade || addressesErrorApi[key].error ? false:null"
                  label="Cidade"
                  :label-for="'cidade-input'+key"
                  label-class="font_size_label_empresa"
                >
                  <v-select
                    :id="'cidades-input'+key"
                    :disabled="addressesInternalUse[key].cidadeDisabled"
                    :options="addressesInternalUse[key].cidades"
                    v-model="addressesInternalUse[key].cidade"
                    placeholder="Selecione a cidade"
                    label="nome"
                    item-text="nome"
                    item-value="id_cidade"
                    @input="(value) => {emitUpdateEnderecoTabContato(); preecheCidadeEndereco(value, key)}"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="addressesErrorApi[key].id_cidade" class="text-danger">
                    {{ addressesErrorApi[key].id_cidade }}
                  </small>
                  <small v-if="addressesErrorApi[key].error" class="text-danger">
                      {{ addressesErrorApi[key].error }}
                  </small>
                </b-form-group>
              </b-overlay>
            </b-col>
             <b-col xl="4" cols="12" class="d-flex justify-content-start align-items-start">
              <b-button
                variant="danger" 
                class="mt-bt-remove"
                @click.prevent="removeAdress(key)">
              <feather-icon icon="XIcon"/>
                Remover endereço
              
              </b-button>
              <b-button 
                v-if="!temContatoCompleto(address.contato)"
                id="modalCadastroContatoEndereco" 
                variant="link" 
                class="colorBlue mt-bt-remove" 
                @click.prevent="openModalCadastroContatoEndereco(address.contato)">
                <feather-icon icon="PlusIcon"/>
                Cadastrar contato
              </b-button>
            </b-col>
          </b-row>
          <div class="containerEnderecoContato d-flex justify-content-between">
            <div class="containerContato">
              <p class="titleEnderecoContato">Contato adicionado a esse endereço:</p>
              <p class="textEnderecoContato" v-if="temContatoCompleto(address.contato)">
                  {{ 
                      (address.contato.nome ? address.contato.nome : '--') + ', ' + 
                      (address.contato.cargo ? address.contato.cargo : '--') + ', E-mail: ' + 
                      (address.contato.email ? address.contato.email : '--') + ' - '
                  }} 

                  {{ (address.contato.telefone ? address.contato.telefone : '--') | VMask('(##)#####-####') }}.
              </p>
              <p class="textEnderecoContato" v-else >-</p>
            </div>
            <div class="containerEditButton d-flex justify-content-center align-items-center">
              <feather-icon 
                v-if="temContatoCompleto(address.contato)"
                id="editContatoExtras" 
                class="text-custom-blue" 
                role="button"
                icon="EditIcon" 
                size="18"
                @click.prevent="openModalCadastroContatoEndereco(address.contato)"
              />
            </div>
          </div>
        </b-overlay>
      </div>
      <b-button 
          id="add-address" 
          @click.prevent="addAddress()"
          variant="custom-blue"
      >
          <feather-icon icon="PlusIcon" class="mr-50"/>
          <span class="align-middle">Adicionar endereço</span>
      </b-button>
    </div>
  </section>
</template>
<script>

import { BCard, BFormInput, BCol, BRow, BOverlay, BFormGroup, BFormRadio, BButton, BLink } from 'bootstrap-vue'
import vSelect from "vue-select"
import VueCleave from 'vue-cleave-component'
import { ValidationProvider } from "vee-validate";
import CadastroContatoModal from '@/views/components/custom/modals/empresa/CadastroContatoModal.vue'; 
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import {warningMessageAction} from '@/libs/sweetalerts';
import { getEstadosEnderecoEmpresa, getCidadeEstadoEnderecoEmpresa } from "@core/utils/store/getStore";
import { setEstadosEnderecoEmpresa, setCidadeEstadoEnderecoEmpresa } from "@core/utils/store/setStore";
  

export default {
    components: {
        BCard,
        BFormInput,
        BCol,
        BRow,
        BOverlay,
        BFormGroup,
        BFormRadio,
        BButton,
        BLink,
        vSelect,
        VueCleave,
        ValidationProvider,
        CadastroContatoModal,
        FeatherIcon
    },

    props: {
      errorApi: {
        type: Object,
        required: true
      },
      value: {
        type: Object,
        required: true
      },
      cidade: {
        type: Object,
        required: false
      },
      isEdit: {
        type: Boolean,
        required: false
      },
      idCompany: {
        type: Number,
        required: false
      }
    },

    data() {
        return {
          casoEdicao: false,
          loadingAdress: [],
          cepError: '',
          estadoSelecionado: null,
          loadingShowCep: false,
          loadingShowCidade: false,
          cidades: [],
          estados: [],
          cidadeDisabled: false,
          estadoDisabled: false,
          mascaraCep: {
            delimiters: ['-'],
            blocks: [5, 3],
            numericOnly: true
          },
          erros: null,
          externalApiFindingData: false,
          addresses: [],
          addressesInternalUse: [],
          addressesErrorApi: [],
          editAddAddress: false,
          showModalEndereco: false,
          contatoEnderecoSelecionado: {}
        }
    },

  mounted() {
    this.buscaEstados();
  },

  methods: {
    async buscaEstados(key = null) {
      if(getEstadosEnderecoEmpresa() === null) {
        const { data } = await this.$http.get(this.$api.getEstados());

        setEstadosEnderecoEmpresa(data);
        this.estados = data;
      }else{
        this.estados = getEstadosEnderecoEmpresa();
      }

      if(key !== null){
        this.addressesInternalUse[key].estados = getEstadosEnderecoEmpresa();
      }
    },

    async buscaCidades(uf, key = null) {
      const cidadeEstadoExiste = getCidadeEstadoEnderecoEmpresa() === null;

      if(cidadeEstadoExiste || getCidadeEstadoEnderecoEmpresa()[uf] === undefined) {
        const { data } = await this.$http.get(this.$api.getCidadesDoEstado(uf));

        const salvaCidadeEstado = {};
        salvaCidadeEstado[uf] = data;
        setCidadeEstadoEnderecoEmpresa(salvaCidadeEstado);
      }

      if(key !== null){
        this.addressesInternalUse[key].loadingShowCidade = true;
        this.$emit('update:travaSaving', true)

        if(!this.loadingAdress[key]){
          this.addressesInternalUse[key].cidade = null;
          this.addressesInternalUse[key].cidadeDisabled = false;
        }
        this.addressesInternalUse[key].cidades = getCidadeEstadoEnderecoEmpresa()[uf].cidades;
        this.addressesInternalUse[key].loadingShowCidade = false;

        this.$emit('update:travaSaving', false)
      }else{
        this.loadingShowCidade = true;
        this.$emit('update:travaSaving', true)
        this.selecionaCidade(null);
        this.cidadeDisabled = false;
        this.cidades = getCidadeEstadoEnderecoEmpresa()[uf].cidades;
        this.loadingShowCidade = false;
        this.$emit('update:travaSaving', false)
      }
    },

    validaCep(key = null) {
        if(key !== null){
          this.addressesInternalUse[key].cepError = '';
          if(this.addresses[key].cep.length == 8){
            this.buscaCep(this.addresses[key].cep, key);
          }else{
            this.addressesInternalUse[key].estadoDisabled = false;
            this.addressesInternalUse[key].cidadeDisabled = false;
          }
        }else{
          this.cepError = '';
          if(this.value.cep.length == 8 && !this.externalApiFindingData){
            this.buscaCep(this.value.cep);
          }else{
            this.estadoDisabled = false;
            this.cidadeDisabled = false;
          }
        }
    },

    async buscaCep(cep, key = null) {
      if(key !== null){
        this.addressesInternalUse[key].loadingShowCep = true;
        this.$emit('update:travaSaving', true)
        await this.$http.get(this.$api.cidadesBuscarPorCep(cep), {
          params: {
            idEmpresa: this.isEdit ? this.idCompany : null
          }
        }).then(async (res) => {
          let cepInfo = res.data;
          if(cepInfo && Object.keys(cepInfo).length !== 0 && Object.getPrototypeOf(cepInfo) === Object.prototype){
            if(cepInfo.estado){
              this.addressesInternalUse[key].estadoSelecionado = {
                uf: cepInfo.uf,
                nome: cepInfo.estado.nome,
              }
              await this.buscaCidades(cepInfo.uf, key);
            }
            if(cepInfo.id_cidade){
              this.addressesInternalUse[key].cidade = {
                id_cidade: cepInfo.id_cidade,
                nome: cepInfo.nome,
                uf: cepInfo.uf
              }
            }
            this.loadingAdress[key] = true;
            this.addresses[key].logradouro = cepInfo.logradouro;
            this.addresses[key].bairro = cepInfo.bairro;
            this.addressesInternalUse[key].cidadeDisabled = !!cepInfo.estado;
            this.addressesInternalUse[key].estadoDisabled = !!cepInfo.estado;
          }
        }).catch((errors) => {
          if(errors && errors.response && errors.response.status == 422){
            this.addressesInternalUse[key].cepError = errors.response.data.errors.cep[0] 
          }else{
              this.addressesInternalUse[key].cepError = "Não foi possível encontrar o cep, mas pode continuar o preenchimento dos dados"
          }
          this.addressesInternalUse[key].cidadeDisabled = false;
            this.addressesInternalUse[key].estadoDisabled = false;
        });
        this.$emit('update:travaSaving', false)
        this.addressesInternalUse[key].loadingShowCep = false;  
      }else{
        this.loadingShowCep = true;
        this.$emit('update:travaSaving', true)
        await this.$http.get(this.$api.cidadesBuscarPorCep(cep), {
          params: {
            idEmpresa: this.isEdit ? this.idCompany : null
          }
        }).then(async (res) => {
          let cepInfo = res.data;
          if(cepInfo && Object.keys(cepInfo).length !== 0 && Object.getPrototypeOf(cepInfo) === Object.prototype){
            this.estadoSelecionado = {
              uf: cepInfo.uf,
              nome: cepInfo.estado.nome,
            }

            const cidadeSelecionada = {
              id_cidade: cepInfo.id_cidade,
              nome: cepInfo.nome,
              uf: cepInfo.uf
            }
            await this.buscaCidades(cepInfo.uf);
            this.preencheFormularioEnderecoApi(
              cidadeSelecionada,
              cepInfo.logradouro,
              cepInfo.bairro
            )
            this.cidadeDisabled = true;
            this.estadoDisabled = true;
          }
        }).catch((errors) => {
          if(errors && errors.response && errors.response.status == 422){
            this.cepError = errors.response.data.errors.cep[0] 
          }else{
            this.cepError = "Não foi possível encontrar o cep, mas pode continuar o preenchimento dos dados"
          }
          this.cidadeDisabled = false;
          this.estadoDisabled = false;
        });
        this.$emit('update:travaSaving', false)
        this.loadingShowCep = false;  
      }
    },

    preencheCidades(estado) {
      if (estado) {
        this.buscaCidades(estado.uf);
        this.$emit('disparaAlertaEstadoDepartamento', estado)
      } else {
        this.cidade = null;
        this.cidadeDisabled = true;
      }

      this.preecnheEstadoEndereco(estado, null); 
      this.emitUpdateEnderecoTabContato();
    },

    preencheCidadesAdicional(estado, key) {
      if (estado) {
        this.buscaCidades(estado.uf, key);
        this.$emit('disparaAlertaEstadoDepartamento', estado)
      } else {
        this.addressesInternalUse[key].cidadeDisabled = true;
        this.addressesInternalUse[key].cidade = null
        this.loadingAdress[key] = false;
      }
    },

    selecionaCidade(cidade) {
      this.$emit("getCidade", cidade);
      this.preecheCidadeEndereco(cidade, null);
      this.emitUpdateEnderecoTabContato();
    },

    preencheFormularioEnderecoApi(cidade, logradouro, bairro) {
      this.selecionaCidade(cidade)
      this.$emit("update:preencheLogradouro", logradouro);
      this.$emit("update:preencheBairro", bairro);
    },

    addAddress() {
      const address = {
        cep: null,
        numero: null,
        ponto_referencia: null,
        id_cidade: null,
        logradouro: null,
        cidade: null,
        estado: null,
        bairro: null,
        id_empresa_endereco: null,
        contato: {
          nome: null,
          cargo: null,
          email: null,
          telefone: null,
          telefone_secundario: null,
          logradouro: null,
          numero: null,
          bairro: null,
          cidade: null,
          estado: null,
          cep: null,
        }
      }

      this.addresses.push({...address});
      this.addressesErrorApi.push({...address});

      this.addressesInternalUse.push(
        {
          cepError: '',
          estadoSelecionado: null,
          loadingShowCep: false,
          loadingShowCidade: false,
          cidades: [],
          estados: [],
          cidadeDisabled: true,
          cidade: null
        }
      );

      this.buscaEstados(this.addressesInternalUse.length -1);
		  this.$emit('addedAddress');
    },
    
    removeAdress(key){
      warningMessageAction(`Tem certeza que deseja remover o ${key+2}° endereço?`, 'A alteração será aplicada após salvar a empresa', 'Sim, remover').then(() => {
        this.addresses.splice(key, 1);
        this.addressesInternalUse.splice(key, 1);
        this.addressesErrorApi.splice(key, 1);
        this.$emit('addedAddress');
      })
    },

    redirectToFaqSection(){
      this.$router.push(
        {
          name: 'pages-faq',
          params: {
              idAjuda: 66,
              categoria: 5
          }
        }
      ); 
    },

    emitUpdateEnderecoTabContato() {
      this.$emit('updateEnderecoTabContato');
    },

    preecnheEstadoEndereco(estado, key) {
      if(key !== null || key === 0) {
        this.addresses[key].estado = estado ? estado.nome : null;
      } else {
        this.value.estado = estado ? estado.nome : null;
      }
    },
 
    preecheCidadeEndereco(cidade, key) {
      if(key !== null || key === 0) {
        this.addresses[key].cidade = cidade ? cidade.nome : null;
      } else {
        this.value.cidade = cidade ? cidade.nome : null;
      }
    },
    
    openModalCadastroContatoEndereco(enderecoContato) {
      this.contatoEnderecoSelecionado = enderecoContato;
      this.showModalEndereco = true;
    },

    closeModal() {
      this.showModalEndereco = false;
    },

    temContatoCompleto(endereco) { 
      if(endereco.nome && endereco.cargo && endereco.email && endereco.telefone) {
        return true; 
      } 

      return false;
    }
  },

  watch: {
    value() {
        this.$emit('input', this.value);
    },
    addresses: {
      handler () {
        this.emitUpdateEnderecoTabContato();

        let verify = false;
        for (const [index, value] of this.addresses.entries()) {
          for(const prop in value) {
            if(prop != 'ponto_referencia' && prop != 'numero' && prop != 'id_cidade' && prop != 'id_empresa_endereco' && (value[prop] == null || value[prop] == '')){
              verify = true;
              break;
            }
            if(prop == 'id_cidade' && (this.addressesInternalUse[index].cidade == null)){
              verify = true;
              break;
            }
          }
        }
        if(verify){
          this.$emit('update:observerAditionalAddresses', null)
        }else{
          this.$emit('update:observerAditionalAddresses', true)
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped >
  .label_endereco{
      font-size: 16px;
      font-weight: 900;
  }
  .label_ajuda{
    font-size: 12px;
    font-weight: 900;
  }

  .colorBlue {
    color: #2772C0;
    margin-top: 10px;
  }

  .containerContato {
    width: 92%
  }

  .containerEditButton {
    width: 8%;
  }

  .textEnderecoContato {
      font-size: 1rem;
      padding: 0;
      margin: 0
  }

  .titleEnderecoContato{
      font-size: 1.2rem;
  }

  .containerEnderecoContato {
      width: 100%;
      background: #0020600A;
      padding: 12px 22px 12px 22px;
      border-radius: 4px;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 10px;
      margin-left: 0;
      margin-bottom: 2rem;
  }

  .mt-bt-remove{
    margin-top: 1.8rem
  }
</style>
<template>
  <div>
    <b-row>
      <!-- Empresa com convênio? -->
      <b-col xl="4" cols="4">
        <div class="radio-inline-spacing-valor">
          <b-form-group
            label="Empresa com convênio?"
            label-for="possui_convenio-empresa"
            label-class="font_size_label_empresa"
          >
            <b-form-radio
              v-model="empresa.possui_convenio"
              name="possui_convenio-empresa"
              :value="true"
            >
              Sim
            </b-form-radio>
            <b-form-radio
              v-model="empresa.possui_convenio"
              name="possui_convenio-empresa"
              :value="false"
            >
              Não
            </b-form-radio>
          </b-form-group>
        </div>
        <small v-if="errorApi.possui_convenio" class="text-danger">
          {{ errorApi.possui_convenio }}
        </small>
        <small v-if="errorApi.error" class="text-danger">
          {{ errorApi.error }}
        </small>
      </b-col>

      <!-- Empresa com valor diferenciado? -->
      <b-col xl="4" cols="4" v-if="naoIndustria">
        <div class="radio-inline-spacing-valor">
          <b-form-group
            label="Empresa com valor diferenciado?"
            label-for="radio-valor-diferenciado"
            label-class="font_size_label_empresa"
          >
            <b-form-radio
              v-model="empresa.valor_diferenciado"
              name="radio-valor-diferenciado"
              :value="true"
            >
              Sim
            </b-form-radio>
            <b-form-radio
              v-model="empresa.valor_diferenciado"
              name="radio-valor-diferenciado"
              :value="false"
            >
              Não
            </b-form-radio>
          </b-form-group>
        </div>
        <small v-if="errorApi.valor_diferenciado" class="text-danger">
          {{ errorApi.valor_diferenciado }}
        </small>
        <small v-if="errorApi.error" class="text-danger">
          {{ errorApi.error }}
        </small>
      </b-col>

      <!-- Escolha uma categoria -->
      <b-col xl="4" cols="4" v-if="naoIndustria && empresa.valor_diferenciado">
        <b-form-group
          :state="errorApi.id_tipo_preco || errorApi.error ? false : null"
          label="Escolha uma categoria"
          label-for="categoria-input"
          label-class="font_size_label_empresa"
          class="m-0"
        >
          <v-select
            id="categoria-input"
            :options="tiposPreco"
            v-model="empresa.id_tipo_preco"
            placeholder="Selecione a categoria"
            label="descricao"
            item-text="descricao"
            item-value="id_tipo_preco"
            :reduce="(option) => option.id_tipo_preco"
            :clearable="false"
            :disabled="!empresa.valor_diferenciado"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
          <small v-if="errorApi.id_tipo_preco" class="text-danger">
            {{ errorApi.id_tipo_preco }}
          </small>
          <small v-if="errorApi.error" class="text-danger">
            {{ errorApi.error }}
          </small>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
    BFormGroup,
    vSelect,
  },
  props: {
    naoIndustria: {
      type: Boolean,
      required: true,
    },
    errorApi: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      casoEdicao: false,
      tiposPreco: [],
      erros: "",
      removeCategoryItem: "Convênio",
      empresa: this.value,
    };
  },

  mounted() {
    this.buscaTiposPrecoEPreenche();
  },

  methods: {
    buscaTiposPrecoEPreenche() {
      this.$http
        .get(this.$api.getTipoPreco(), { cache: false })
        .then(({ data }) => {
          if (this.naoIndustria) {
            this.tiposPreco = data.filter(
              (item) => item.descricao !== this.removeCategoryItem
            );
            return;
          }
          this.tiposPreco = data;
        })
        .catch(() => {
          this.$helpers.modalErroServidorPadrao();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-inline-spacing-valor .form-group .custom-radio {
  display: inline-block;
}
.radio-inline-spacing-valor .form-group .custom-radio:first-child {
  margin-right: 1.5rem;
}
</style>
<template>
	<section>
		<div class="contatoPrincipalEmpresa">
			<div class="mb-2 label_contato" cols="12">Contato Principal *</div>
			<FormContatoEmpresaVue
				v-model="value"
				:errorApi="errorApiContatoPrincipal"
				:showAddress="false"
			/>
		</div>

		<div class="mt-3 contatosEnderecosEmpresa"  v-for="(contatoEndereco, index) in dadosContatoEndereco" :key="index">
			<div class="mb-2 label_contato" cols="12">Contato - Endereço {{ index + 1 }}</div>
			<FormContatoEmpresaVue
				v-model="contatoEndereco.contato"
				:errorApi="errorApiEndereco[index]"
				:showAddress="true"
			/>
		</div>
	</section>
</template>
<script>

import FormContatoEmpresaVue from '../forms/FormContatoEmpresa.vue';

export default {
	components: {
		FormContatoEmpresaVue
	},

	props: {
		errorApiContatoPrincipal: {
			type: Object,
			required: true
		},
		errorApiEndereco: {
			type: Array|Object,
			required: true
		},
		value: {
			type: Object,
			required: true
		},
		dadosContatoEndereco: {
			type: Array,
			required: true
		}
	}
}
</script>
<style scoped>
	.label_contato {
		font-size: 16px;
		font-weight: 900;
	}
</style>

<template>
  <section>
    <b-row>
        <b-col xl="12" cols="12">
            <div class="radio-inline-spacing-cnpj">
                <label class="font_size_label_empresa font-weight-light">Usar o mesmo CNPJ para faturamento?</label>
                <b-form-radio
                    :state="errorApi.error ? false:null"
                    v-model="value.radioMesmoCnpjFaturamento"
                    name="radio-mesmo-cnpj"
                    :value="true"
                >
                    Sim
                </b-form-radio>
                <b-form-radio
                    :state="errorApi.error ? false:null"
                    v-model="value.radioMesmoCnpjFaturamento"
                    name="radio-mesmo-cnpj"
                    :value="false"
                >
                    Não
                </b-form-radio>
            </div>
            <small v-if="errorApi.error" class="text-danger">
                {{ errorApi.error }}
            </small>
        </b-col>
        <b-col
            v-if="value.radioMesmoCnpjFaturamento == true"
            xl="5" 
            cols="12"  
            class="mt-2"
        >
            <b-form-group 
            label="CNPJ de Faturamento"
            label-for="mesmo-cnpj-faturamento-input"
            label-class="font_size_label_empresa">
                <b-form-input
                    id="mesmo-cnpj-faturamento-input" 
                    placeholder="00.000.000/0000-00"
                    v-model="mesmoCnpjFaturamento"
                    v-mask="'##.###.###/####-##'"
                    :disabled="true"
                />
                <small v-if="errorApi.cnpj_faturamento" class="text-danger">
                    {{ errorApi.cnpj_faturamento }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                    {{ errorApi.error }}
                </small>
            </b-form-group>
        </b-col>
        <b-col
            v-else
            xl="5" 
            cols="12"  
            class="mt-2"
        >
            <b-form-group 
                label="CNPJ de Faturamento"
                label-for="cnpj-faturamento-input"
                label-class="font_size_label_empresa"
            >
                <validation-provider
                    #default="{ errors }"
                    name="CNPJFaturamento"
                    :rules="'required|length:14'"
                >
                    <vue-cleave
                        id="cnpj-faturamento-input" 
                        v-model="value.cnpjFaturamento"
                        :raw="true"
                        :options="mascaraCnpj"
                        class="form-control"
                        placeholder="00.000.000/0000-00"
                        name="CnpjFaturamento"
                    />
                    <small v-if="errors[0]" class="text-danger">
                        Este campo deve ter 14 números
                    </small>
                    <small v-if="errorApi.cnpj_faturamento" class="text-danger">
                        {{ errorApi.cnpj_faturamento }}
                    </small>
                    <small v-if="errorApi.error" class="text-danger">
                        {{ errorApi.error }}
                    </small>
                </validation-provider>
            </b-form-group>
        </b-col>
    </b-row>
  </section>
</template>
<script>

import { BCard, BFormInput, BCol, BRow, BOverlay, BFormGroup, BFormRadio } from 'bootstrap-vue'
import { ValidationProvider } from "vee-validate"
import VueCleave from 'vue-cleave-component'

export default {
    components: {
        BCard,
        BFormInput,
        BCol,
        BRow,
        BOverlay,
        BFormGroup,
        BFormRadio,
        ValidationProvider,
        VueCleave
    },

    props: {
        errorApi: {
            type: Object,
            required: true
        },
        mesmoCnpjFaturamento: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            mascaraCnpj: {
                delimiters: ['.', '.', '/', '-'],
                blocks: [2, 3, 3, 4, 2],
                numericOnly: true
            },
        }
    },

    watch: {
        value() {
            this.$emit('input', this.value);
        }
    }

}
</script>
<style lang="scss" scoped>
.radio-inline-spacing-cnpj {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-bottom: 0.2rem;
  }
}
</style>